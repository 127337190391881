<template>
    <div style="margin:-12px;width:100vw;">

        <!-- app bar -->
        <top-nav title="Listado de órdenes">
            <template v-slot:actions>
                <v-badge :value="badgeQty > 0" :content="badgeQty" color="accent" overlap offset-x="25" offset-y="20" left>
                    <v-btn icon depressed @click="showFilters = !showFilters">
                        <v-icon color="white">mdi-filter-outline</v-icon>
                    </v-btn>
                </v-badge>
            </template>
        </top-nav>

        <v-slide-y-transition>
            <div class="px-3 py-2 grey lighten-4" v-show="showFilters">
                <v-chip small class="mx-1" @click="showStatusDialog = true" :color="statusFilterValue == null ? '' : statusFilterValue.color"
                    :dark="statusFilterValue != null">
                    <v-icon small left>mdi-delta</v-icon> {{ statusFilterValue == 'all' ? 'Estatus' : statusFilterValue.text }}
                </v-chip>
                <v-chip small class="mx-1" @click="showDateDialog = true" :color="dateFilterValue == null ? '' : 'accent'">
                    <v-icon small left>mdi-calendar</v-icon> Fecha
                </v-chip>
                <!-- <v-chip small class="mx-1"><v-icon small left>mdi-account</v-icon> Cliente</v-chip> -->
            </div>
        </v-slide-y-transition>

        <div v-if="orders != null" class="mt-5">
            <v-text-field v-model="q" class="rounded-xl mb-3 mx-3 mt-2" flat solo background-color="grey lighten-3" label="Buscar" hide-details dense prepend-inner-icon="mdi-magnify" clearable></v-text-field>
            <div v-if="q && filteredOrders.length === 0" class="d-flex justify-center align-center px-3" style="width: 100%;line-break:anywhere;">
                <v-icon class="mr-2">mdi-alert-circle-outline</v-icon>
                <span>No se encontraron resultados para <strong>"{{ q }}"</strong></span>
            </div>
            <div v-else-if="q" class="d-flex justify-center align-center px-3" style="width: 100%;line-break:anywhere;">
                <span>Mostrando {{ filteredOrders.length }} {{ resultText }} para <strong>"{{ q }}"</strong></span>
            </div>
            <!-- content -->
            <v-list two-line>
                <template v-for="(order, ix) in filteredOrders">
                    <v-list-item :key="order.id" :to="'/secure/order/' + order.id">
                        <v-list-item-content>
                            <v-list-item-title class="text-left" v-if="order.customer != null">{{ order.customer.firstName }} {{ order.customer.lastName }}</v-list-item-title>
                            <v-list-item-subtitle class="text-left">{{ order.orderNumber.toUpperCase() }}</v-list-item-subtitle>
                            <v-list-item-subtitle class="text-left">
                                {{ toMoney( order.total ) }}
                            </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                            <v-chip label x-small :color="getOrderStatusColor( order.status )" class="white--text">{{ order.status }}</v-chip>
                        </v-list-item-action>
                    </v-list-item>

                    <v-divider inset v-if="ix < orders.length - 1" :key="order.id + '-divider'"></v-divider>
                </template>
            </v-list>

            <v-btn color="primary" dark bottom right fab fixed class="addBtn" to="/secure/new-order">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </div>

        <v-skeleton-loader class="mx-auto" type="list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line" v-else></v-skeleton-loader>

        <!-- status filter dialog -->
        <v-dialog v-model="showStatusDialog">
            <v-card>
                <v-card-title>Filtrar por estatus</v-card-title>
                <v-card-text class="pb-0">
                    <v-list>
                        <v-list-item v-for="s in status" :key="s.name" @click="setFilter('status', s)">
                            <v-list-item-avatar class="my-0" size="16">
                                <v-avatar :color="s.color"></v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-title>{{ s.text }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-card-text>
                <v-card-actions>
                    <v-btn block depressed color="primary" @click="removeFilter('status')">Limpiar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="showDateDialog">
            <v-card>
                <v-card-title>Filtrar por fecha</v-card-title>
                <v-card-text>
                    <v-switch v-model="dateRange" label="Usar periodo de fechas"></v-switch>
                    <v-date-picker v-model="dates" :range="dateRange" no-title></v-date-picker>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed @click="removeFilter('date')">Limpiar</v-btn>
                    <v-btn depressed color="primary" @click="setFilter('date', dates)">Ok</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import TopNav from '@/components/admin/TopNav.vue';
import { mapGetters, mapActions } from 'vuex';
import { _st } from '@/softech';
import moment from 'moment';

export default {
    data() {
        return {
            q: '',
            filters             : [
                { type: 'status', value: 'all' },
                { type: 'date', value: [null, null] },
            ],

            showFilters         : false,
            showStatusDialog    : false,
            showDateDialog      : false,
            
            dateRange           : true,
            dates               : [],
            status              : [
                { text: 'Processing', value: 'processing', color: 'orange' },
                { text: 'Completed', value: 'completed', color: 'green' },
                { text: 'Cancelled', value: 'cancelled', color: 'blue-grey' },
                { text: 'Pending Payment', value: 'pending payment', color: 'amber' },
                { text: 'Shipped', value: 'shipped', color: 'teal' },
                { text: 'Failed', value: 'failed', color: 'red' },
                { text: 'Draft', value: 'draft', color: 'grey' },
                { text: 'Refunded', value: 'refunded', color: 'purple' },
            ]
        }
    },
    computed: {
        ...mapGetters({
            user                : 'auth/user',
            orders              : 'ordersInfo/orders'
        }),
        filteredOrders() {
            let q = this.q?.toLowerCase() || null
            return !this.q 
            ? this.orders
            : this.orders.filter((o) => {
                return o.customer?.firstName.toLowerCase().includes(q)
                || o.customer?.lastName.toLowerCase().includes(q)
                || o.orderNumber.toLowerCase().includes(q)
                || o.status.toLowerCase().includes(q)
            })
        },
        statusFilterValue() {
            let status = this.filters.find((f) => f.type == 'status');
            return _st.isNUE(status) ? null : status.value;
        },
        dateFilterValue() {
            let status = this.filters.find((f) => f.type == 'date');
            return _st.isNUE( status.value[0] ) && _st.isNUE( status.value[1] ) ? null : `${status.value[1]} - ${status.value[1]}`;
        },
        resultText() {
            return this.filteredOrders.length === 1 ? 'resultado' : 'resultados'
        },
        badgeQty() {
            let total = 0;
            if( this.filters.find(f => f.type == 'status')?.value != 'all' )
                total++;
            if( this.dateFilterValue != null )
                total++;

            return total;
        }
    },
    watch: {
        async filters() {
            this.loadData();
        },
        dateRange(v) {
            if( !v && _st.isArray( this.dates ) && this.dates.length == 2 )
                this.dates = this.dates[0];
        }
    },
    async mounted() {
        await this.loadOrders({sortDirection: 'DESC'});
    },
    methods: {
        ...mapActions({ 
            loadOrders: 'ordersInfo/getOrdersData' 
        }),
        async loadData() {
            let from = null;
            let to = null;
            let status = null;

            this.filters.forEach((f) => {
                if( f.type == 'status' )
                    status = f.value.value;
                if( f.type == 'date' ) {
                    if( _st.isArray( f.value ) ) {
                        from = moment( f.value[0] ).format('YYYY-MM-DD');
                        to = moment( f.value[1] ).format('YYYY-MM-DD');
                    }
                    else {
                        from = moment( f.value ).format('YYYY-MM-DD');
                        to = moment( f.value ).format('YYYY-MM-DD');
                    }
                }
            });
            
            await this.loadOrders({ from, to, status });
        },
        toMoney(value) {
            return _st.toMoney(value)
        },
        getOrderStatusColor(status) {
            switch(status) {
                case 'processing'       : return 'orange'
                case 'completed'        : return 'green'
                case 'cancelled'        : return 'blue-grey'
                case 'pending payment'   : return 'amber'
                case 'shipped'          : return 'teal'
                case 'failed'           : return 'red'
                case 'draft'            : return 'grey'
                case 'refunded'         : return 'purple'
            }
        },
        setFilter( type, value ) {
            // remove previous filters
            this.filters = this.filters.filter((f) => f.type != type);
            this.filters.push({ type, value });
            this.closeFilterDialogs();
        },
        removeFilter( type ) {
            this.filters.forEach(f => {
                if( f.type == type ) {
                    f.value = f.type == 'status' ? 'all' : [null, null];
                }
            });
            // this.filters = this.filters.filter((f) => f.type != type);
            this.closeFilterDialogs();
            this.loadData();
        },
        closeFilterDialogs() {
            this.showStatusDialog = this.showDateDialog = false;
        }
    },
    components: { TopNav }
}
</script>

<style lang="scss" scoped>
.addBtn {
    bottom: 15px !important;
}
</style>